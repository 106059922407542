.ant-pro-table-dropdown {
  width: auto;
}

.ant-pro-table-list-toolbar {
  overflow-x: auto;
  line-height: 1;
}
.ant-pro-table-list-toolbar-container {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}
.ant-pro-table-list-toolbar-container-mobile {
  flex-direction: column;
}
.ant-pro-table-list-toolbar-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
}
.ant-pro-table-list-toolbar-search:not(:last-child) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ant-pro-table-list-toolbar-setting-item {
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  cursor: pointer;
}
.ant-pro-table-list-toolbar-setting-item > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-pro-table-list-toolbar-setting-item:hover {
  color: #40a9ff;
}
.ant-pro-table-list-toolbar-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ant-pro-table-list-toolbar-right {
  display: flex;
  justify-content: flex-end;
}
.ant-pro-table-list-toolbar-extra-line {
  margin-bottom: 16px;
}
.ant-pro-table-list-toolbar-filter {
  display: flex;
  align-items: center;
}
.ant-pro-table-list-toolbar-filter:not(:last-child) {
  margin-right: 16px;
}
.ant-pro-table-list-toolbar-filter .ant-pro-table-search {
  margin: 0;
  padding: 0;
}
.ant-pro-table-list-toolbar-inline-menu-item {
  display: inline-block;
  margin-right: 24px;
  cursor: pointer;
  opacity: 0.75;
}
.ant-pro-table-list-toolbar-inline-menu-item-active {
  font-weight: bold;
  opacity: 1;
}
.ant-pro-table-list-toolbar-dropdownmenu-label {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.ant-pro-table-list-toolbar .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}
.ant-pro-table-list-toolbar .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}
.ant-pro-table-list-toolbar .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-list {
  margin-top: 0;
}
.ant-pro-table-list-toolbar .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  padding-top: 0;
}
@media (max-width: 575px) {
  .ant-pro-table-list-toolbar-container {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-pro-table-list-toolbar-left {
    margin-bottom: 16px;
  }
}

.ant-pro-core-label-tip {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
}
.ant-pro-core-label-tip-icon {
  display: block;
  margin-left: 4px;
  cursor: pointer;
}
.ant-pro-core-label-tip-icon:hover {
  color: #40a9ff;
}
.ant-pro-core-label-tip-title {
  display: inline-flex;
  flex: 1;
}
.ant-pro-core-label-tip-subtitle {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
}
.ant-pro-core-label-tip-title-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.ant-pro-field-dropdown {
  width: auto;
}

.ant-pro-table .ant-pro-table-search {
  margin-bottom: 16px;
  padding: 24px;
  padding-bottom: 0;
  background: #fff;
}
.ant-pro-table .ant-pro-table-search-ghost {
  background: transparent;
}
.ant-pro-table .ant-pro-table-search::before {
  display: table;
  content: '';
}
.ant-pro-table .ant-pro-table-search::after {
  display: table;
  clear: both;
  content: '';
}
.ant-pro-table .ant-pro-table-search.ant-pro-table-form {
  margin: 0;
  padding: 0 16px;
  overflow: unset;
}
.ant-pro-table .ant-pro-table-search-light {
  margin-bottom: 0;
  padding: 16px 0;
}
.ant-pro-table .ant-pro-table-search-form-option .ant-form-item {
  margin: 0;
}
.ant-pro-table .ant-pro-table-search-form-option .ant-form-item-label {
  opacity: 0;
}
.ant-pro-table .ant-pro-table-search-form-option .ant-form-item-control-input {
  justify-content: flex-start;
}
@media (max-width: 575px) {
  .ant-pro-table .ant-pro-table-search {
    height: auto !important;
    padding-bottom: 24px;
  }
  .ant-pro-table .ant-pro-table-search .ant-form-item-label {
    min-width: 80px;
    text-align: left;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #1890ff;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-pro-form-query-filter {
  /*暂时不需要内容*/
}

.ant-pro-form-light-filter {
  line-height: 30px;
}
.ant-pro-form-light-filter::before {
  display: block;
  height: 0;
  visibility: hidden;
  content: '.';
}
.ant-pro-form-light-filter-small {
  line-height: 1.5715;
}
.ant-pro-form-light-filter-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-right: -4px;
}
.ant-pro-form-light-filter-item {
  margin-top: 8px;
  white-space: nowrap;
}
.ant-pro-form-light-filter-item:not(:last-child) {
  margin-right: 8px;
}
.ant-pro-form-light-filter-formlabel {
  margin-bottom: 2px;
}
.ant-pro-form-light-filter-line {
  min-width: 198px;
}
.ant-pro-form-light-filter-line .ant-form-item {
  flex-direction: column;
  margin-bottom: 0;
}
.ant-pro-form-light-filter-line:not(:first-child) {
  margin-top: 16px;
  margin-bottom: 8px;
}
.ant-pro-form-light-filter .ant-form-item {
  margin-bottom: 0;
}
.ant-pro-form-light-filter-collapse-icon {
  width: 32px;
  height: 32px;
  line-height: 35px;
  border-radius: 50%;
}
.ant-pro-form-light-filter-effective .ant-pro-form-light-filter-collapse-icon {
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-pro-core-field-label {
  display: inline-block;
  height: 30px;
  padding: 0 4px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 2px;
  cursor: pointer;
}
.ant-pro-core-field-label:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.ant-pro-core-field-label-active {
  padding: 0 12px;
  background-color: rgba(0, 0, 0, 0.04);
}
.ant-pro-core-field-label-active.ant-pro-core-field-label-allow-clear:hover:not(.ant-pro-core-field-label-disabled) .ant-pro-core-field-label-arrow {
  display: none;
}
.ant-pro-core-field-label-active.ant-pro-core-field-label-allow-clear:hover:not(.ant-pro-core-field-label-disabled) .ant-pro-core-field-label-close {
  display: inline-block;
}
.ant-pro-core-field-label-icon {
  margin-top: -2px;
  margin-left: 4px;
  padding: 1px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  vertical-align: middle;
}
.ant-pro-core-field-label-icon.ant-pro-core-field-label-close {
  display: none;
  margin-top: -4px;
  padding: 3px;
  color: #fff;
  font-size: 8px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.ant-pro-core-field-label-icon.ant-pro-core-field-label-close:hover {
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-pro-core-field-label-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-pro-core-field-label-disabled .ant-pro-core-field-label-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-pro-core-field-label-small {
  height: 24px;
  padding: 0 4px;
  font-size: 12px;
  line-height: 24px;
}
.ant-pro-core-field-label-small.ant-pro-core-field-label-active {
  padding: 0 8px;
}
.ant-pro-core-field-label-small .ant-pro-core-field-label-icon {
  padding: 0;
}
.ant-pro-core-field-label-small .ant-pro-core-field-label-close {
  margin-top: -2px;
  padding: 3px;
  font-size: 6px;
}
.ant-pro-core-field-label-bordered {
  height: 32px;
  padding: 0 12px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-pro-core-field-label-bordered.ant-pro-core-field-label-small {
  height: 24px;
  padding: 0 8px;
}
.ant-pro-core-field-label-bordered.ant-pro-core-field-label-active {
  background-color: #fff;
}

.ant-pro-core-field-dropdown-label {
  cursor: pointer;
}
.ant-pro-core-field-dropdown-overlay {
  min-width: 200px;
  margin-top: 4px;
  background-color: #fff;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-pro-core-field-dropdown-content {
  padding: 16px;
}

.ant-pro-core-dropdown-footer {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 8px;
  border-top: 1px solid #f0f0f0;
}

.ant-pro-steps-form-container {
  width: max-content;
  min-width: 520px;
  max-width: 100%;
  margin: auto;
}
.ant-pro-steps-form-steps-container {
  max-width: 1160px;
  margin: auto;
}
.ant-pro-steps-form-steps-container .ant-steps-vertical {
  height: 100%;
}
.ant-pro-steps-form-step {
  display: none;
  margin-top: 32px;
}
.ant-pro-steps-form-step-active {
  display: block;
}
.ant-pro-steps-form-step > form {
  max-width: 100%;
}

.ant-pro-form-group-title {
  margin-bottom: 16px;
  font-weight: bold;
}
.ant-pro-form-group-container {
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-pro-form-group-container > div.ant-space-item {
  max-width: 100%;
}
.ant-pro-form-group-twoLine {
  display: block;
  width: 100%;
}
.ant-pro-form-group-twoLine .ant-pro-form-group-title {
  width: 100%;
  margin: 8px 0;
}
.ant-pro-form-group-twoLine .ant-pro-form-group-container {
  padding-left: 16px;
}
.ant-pro-form-group-twoLine .ant-space-item,
.ant-pro-form-group-twoLine .ant-form-item {
  width: 100%;
}
.ant-pro-form-group-twoLine .ant-form-item-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-pro-form-group-twoLine .ant-form-item-control-input {
  align-items: center;
  justify-content: flex-end;
}
.ant-pro-form-group-twoLine .ant-form-item-control-input-content {
  flex: none;
}

.ant-form:not(.ant-form-horizontal) .ant-pro-form-list-item .ant-form-item-label {
  display: none;
}
.ant-pro-form-list {
  max-width: 100%;
}
.ant-pro-form-list-item.ant-pro-form-list-item-show-label .ant-form-item-label {
  display: inline-block;
}
.ant-pro-form-list-item:first-of-type div:first-of-type .ant-form-item .ant-form-item-label {
  display: inline-block;
}
.ant-pro-form-list-action {
  display: flex;
  height: 32px;
  margin-bottom: 24px;
  line-height: 32px;
}
.ant-pro-form-list .ant-pro-card .ant-pro-card-extra .ant-pro-form-list-action {
  margin-bottom: 0;
}
.ant-pro-form-list-action-icon {
  margin-left: 8px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.ant-pro-form-list-action-icon:hover {
  color: #40a9ff;
}
.ant-pro-form-list-creator-button-top {
  margin-bottom: 24px;
}

.ant-pro-field-light-wrapper-collapse-label {
  padding: 1;
}
.ant-pro-field-light-wrapper-container .ant-form-item {
  margin-bottom: 0;
}

.ant-pro-field-date-picker-light .ant-calendar-picker,
.ant-pro-field-date-picker-light .ant-picker {
  position: absolute;
  width: 80px;
  height: 28px;
  overflow: hidden;
  visibility: hidden;
}

.ant-pro-field-index-column {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
}
.ant-pro-field-index-column-border {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background-color: #314659;
  border-radius: 9px;
}
.ant-pro-field-index-column-border.top-three {
  background-color: #979797;
}

.ant-pro-table-dropdown {
  width: auto;
}
.ant-pro-select-item-option-content-light {
  color: #1890ff;
}
.ant-pro-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-pro-field-select-light-select .ant-select {
  position: absolute;
  width: 153px;
  height: 28px;
  visibility: hidden;
}
.ant-pro-field-select-light-select .ant-select-selector {
  height: 28px;
}
.ant-pro-field-select-light-select.ant-pro-field-select-light-select-searchable .ant-select {
  width: 200px;
}
.ant-pro-field-select-light-select.ant-pro-field-select-light-select-searchable .ant-select-selector {
  height: 28px;
}

.ant-pro-field-checkbox-vertical .ant-checkbox-group-item {
  display: flex;
  margin-right: 0;
}

.ant-pro-field-radio-vertical .ant-radio-wrapper {
  display: block;
  margin-right: 0;
}

.pro-table-tooltip-text span {
  color: #fff;
}
.ant-pro-table {
  z-index: 1;
}
.ant-pro-table:not(:root):fullscreen {
  min-height: 100vh;
  overflow: auto;
  background: #fff;
}
.ant-pro-table-extra {
  margin-bottom: 16px;
}
.ant-pro-table-polling .ant-pro-table-list-toolbar-setting-item .anticon.anticon-reload {
  transform: rotate(0deg);
  animation: turn 1s linear infinite;
}
.ant-pro-table td.ant-table-cell > a {
  font-size: 14px;
}
.ant-pro-table .ant-table .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}
.ant-pro-table .ant-table.ant-table-middle .ant-pro-table {
  margin: -12px -8px;
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ant-form > div:not(.ant-pro-form-light-filter) .pro-field {
  max-width: 100%;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-xs {
  width: 104px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-s {
  width: 216px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-sm {
  width: 216px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-m {
  width: 328px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-md {
  width: 328px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-l {
  width: 440px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-lg {
  width: 440px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-xl {
  width: 552px;
}

.ant-pro-card {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 2px;
}
.ant-pro-card-col {
  width: 100%;
}
.ant-pro-card-border {
  border: 1px solid #f0f0f0;
}
.ant-pro-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-pro-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-pro-card-hoverable.ant-pro-card-checked:hover {
  border-color: #1890ff;
}
.ant-pro-card-checked {
  background-color: #e6f7ff;
  border-color: #1890ff;
}
.ant-pro-card-checked::after {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 0;
  height: 0;
  border: 6px solid #1890ff;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top-right-radius: 2px;
  content: '';
}
.ant-pro-card:focus {
  background-color: #e6f7ff;
  border-color: #1890ff;
}
.ant-pro-card-size-small .ant-pro-card-header {
  padding: 8px 12px;
  padding-bottom: 0;
}
.ant-pro-card-size-small .ant-pro-card-header-border {
  padding-bottom: 8px;
}
.ant-pro-card-size-small .ant-pro-card-title {
  font-size: 14px;
}
.ant-pro-card-size-small .ant-pro-card-body {
  padding: 12px;
}
.ant-pro-card-ghost {
  background-color: transparent;
}
.ant-pro-card-ghost > .ant-pro-card-header {
  padding-right: 0;
  padding-bottom: 16px;
  padding-left: 0;
}
.ant-pro-card-ghost > .ant-pro-card-body {
  padding: 0;
  background-color: transparent;
}
.ant-pro-card-split > .ant-pro-card-body {
  padding: 0;
}
.ant-pro-card-split-vertical {
  border-right: 1px solid #f0f0f0;
}
.ant-pro-card-split-horizontal {
  border-bottom: 1px solid #f0f0f0;
}
.ant-pro-card-contain-card > .ant-pro-card-body {
  display: flex;
}
.ant-pro-card-body-direction-column {
  flex-direction: column;
}
.ant-pro-card-body-wrap {
  flex-wrap: wrap;
}
.ant-pro-card-collapse > .ant-pro-card-header {
  padding-bottom: 16px;
  border-bottom: 0;
}
.ant-pro-card-collapse > .ant-pro-card-body {
  display: none;
}
.ant-pro-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  padding-bottom: 0;
}
.ant-pro-card-header-border {
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-pro-card-header-collapsible {
  cursor: pointer;
}
.ant-pro-card-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
}
.ant-pro-card-extra {
  color: rgba(0, 0, 0, 0.85);
}
.ant-pro-card-type-inner .ant-pro-card-header {
  background-color: #fafafa;
}
.ant-pro-card-collapsible-icon {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.75);
}
.ant-pro-card-collapsible-icon :hover {
  color: #40a9ff;
}
.ant-pro-card-collapsible-icon svg {
  transition: transform 0.2s;
}
.ant-pro-card-body {
  display: block;
  box-sizing: border-box;
  height: 100%;
  padding: 24px;
}
.ant-pro-card-body-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pro-card-col-0 {
  display: none;
}
.ant-pro-card-col-24 {
  flex-shrink: 0;
  width: 100%;
}
.ant-pro-card-col-23 {
  flex-shrink: 0;
  width: 95.83333333%;
}
.ant-pro-card-col-22 {
  flex-shrink: 0;
  width: 91.66666667%;
}
.ant-pro-card-col-21 {
  flex-shrink: 0;
  width: 87.5%;
}
.ant-pro-card-col-20 {
  flex-shrink: 0;
  width: 83.33333333%;
}
.ant-pro-card-col-19 {
  flex-shrink: 0;
  width: 79.16666667%;
}
.ant-pro-card-col-18 {
  flex-shrink: 0;
  width: 75%;
}
.ant-pro-card-col-17 {
  flex-shrink: 0;
  width: 70.83333333%;
}
.ant-pro-card-col-16 {
  flex-shrink: 0;
  width: 66.66666667%;
}
.ant-pro-card-col-15 {
  flex-shrink: 0;
  width: 62.5%;
}
.ant-pro-card-col-14 {
  flex-shrink: 0;
  width: 58.33333333%;
}
.ant-pro-card-col-13 {
  flex-shrink: 0;
  width: 54.16666667%;
}
.ant-pro-card-col-12 {
  flex-shrink: 0;
  width: 50%;
}
.ant-pro-card-col-11 {
  flex-shrink: 0;
  width: 45.83333333%;
}
.ant-pro-card-col-10 {
  flex-shrink: 0;
  width: 41.66666667%;
}
.ant-pro-card-col-9 {
  flex-shrink: 0;
  width: 37.5%;
}
.ant-pro-card-col-8 {
  flex-shrink: 0;
  width: 33.33333333%;
}
.ant-pro-card-col-7 {
  flex-shrink: 0;
  width: 29.16666667%;
}
.ant-pro-card-col-6 {
  flex-shrink: 0;
  width: 25%;
}
.ant-pro-card-col-5 {
  flex-shrink: 0;
  width: 20.83333333%;
}
.ant-pro-card-col-4 {
  flex-shrink: 0;
  width: 16.66666667%;
}
.ant-pro-card-col-3 {
  flex-shrink: 0;
  width: 12.5%;
}
.ant-pro-card-col-2 {
  flex-shrink: 0;
  width: 8.33333333%;
}
.ant-pro-card-col-1 {
  flex-shrink: 0;
  width: 4.16666667%;
}

.ant-pro-card-loading {
  overflow: hidden;
}
.ant-pro-card-loading .ant-pro-card-body {
  user-select: none;
}
.ant-pro-card-loading-content {
  width: 100%;
}
.ant-pro-card-loading-content p {
  margin: 0;
}
.ant-pro-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  animation: card-loading 1.4s ease infinite;
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.ant-pro-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.ant-pro-card-actions::before {
  display: table;
  content: '';
}
.ant-pro-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-pro-card-actions .ant-space {
  gap: 0 !important;
  width: 100%;
}
.ant-pro-card-actions > li,
.ant-pro-card-actions .ant-space-item {
  flex: 1;
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-pro-card-actions > li > a,
.ant-pro-card-actions .ant-space-item > a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-pro-card-actions > li > a:hover,
.ant-pro-card-actions .ant-space-item > a:hover {
  color: #40a9ff;
}
.ant-pro-card-actions > li > span,
.ant-pro-card-actions .ant-space-item > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.ant-pro-card-actions > li > span:hover,
.ant-pro-card-actions .ant-space-item > span:hover {
  color: #40a9ff;
  transition: color 0.3s;
}
.ant-pro-card-actions > li > span a:not(.ant-btn),
.ant-pro-card-actions .ant-space-item > span a:not(.ant-btn),
.ant-pro-card-actions > li > span > .anticon,
.ant-pro-card-actions .ant-space-item > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-pro-card-actions > li > span a:not(.ant-btn):hover,
.ant-pro-card-actions .ant-space-item > span a:not(.ant-btn):hover,
.ant-pro-card-actions > li > span > .anticon:hover,
.ant-pro-card-actions .ant-space-item > span > .anticon:hover {
  color: #40a9ff;
}
.ant-pro-card-actions > li > span > .anticon,
.ant-pro-card-actions .ant-space-item > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-pro-card-actions > li:not(:last-child),
.ant-pro-card-actions .ant-space-item:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}

.ant-pro-card-divider {
  flex: none;
  width: 1px;
  margin: 24px 8px;
  background-color: #f0f0f0;
}
.ant-pro-card-divider-horizontal {
  width: initial;
  height: 1px;
  margin: 8px 24px;
}
.ant-pro-card-size-small .ant-pro-card-divider {
  margin: 12px 8px;
}
.ant-pro-card-size-small .ant-pro-card-divider-horizontal {
  margin: 8px 12px;
}

.ant-pro-card-tabs .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}
.ant-pro-card-tabs .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-list {
  margin-top: 8px;
  padding-left: 16px;
}
.ant-pro-card-tabs .ant-tabs-bottom > .ant-tabs-nav {
  margin-top: 0;
}
.ant-pro-card-tabs .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-list {
  padding-left: 16px;
}
.ant-pro-card-tabs .ant-tabs-left .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
  padding-left: 0;
}
.ant-pro-card-tabs .ant-tabs-left > .ant-tabs-nav {
  margin-right: 0;
}
.ant-pro-card-tabs .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list {
  padding-top: 16px;
}
.ant-pro-card-tabs .ant-tabs-right .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
  padding-right: 0;
}
.ant-pro-card-tabs .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list {
  padding-top: 16px;
}

.ant-pro-inline-error-form-item-multiple {
  padding: 6px 8px 12px 8px;
}
.ant-pro-inline-error-form-item-progress-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-pro-inline-error-form-item-progress-error .ant-progress-bg {
  background-color: #ff4d4f;
}
.ant-pro-inline-error-form-item-progress-warning .ant-progress-bg {
  background-color: #faad14;
}
.ant-pro-inline-error-form-item-rule {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pro-inline-error-form-item-rule-content {
  display: flex;
  align-items: center;
}
.ant-pro-inline-error-form-item-rule-content-icon-default {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 22px;
}
.ant-pro-inline-error-form-item-rule-content-icon-default-circle {
  width: 6px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 4px;
}
.ant-pro-inline-error-form-item-rule-content-icon-loading {
  color: #1890ff;
}
.ant-pro-inline-error-form-item-rule-content-icon-error {
  color: #ff4d4f;
}
.ant-pro-inline-error-form-item-rule-content-icon-success {
  color: #52c41a;
}
.ant-pro-inline-error-form-item-rule-content-text {
  color: rgba(0, 0, 0, 0.85);
}

.pro-table-tooltip-text span {
  color: #fff;
}
.ant-pro-table {
  z-index: 1;
}
.ant-pro-table:not(:root):fullscreen {
  min-height: 100vh;
  overflow: auto;
  background: #fff;
}
.ant-pro-table-extra {
  margin-bottom: 16px;
}
.ant-pro-table-polling .ant-pro-table-list-toolbar-setting-item .anticon.anticon-reload {
  transform: rotate(0deg);
  animation: turn 1s linear infinite;
}
.ant-pro-table td.ant-table-cell > a {
  font-size: 14px;
}
.ant-pro-table .ant-table .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}
.ant-pro-table .ant-table.ant-table-middle .ant-pro-table {
  margin: -12px -8px;
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ant-pro-table-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 24px;
}
.ant-pro-table-toolbar-option {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-pro-table-toolbar-title {
  flex: 1;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.85;
}
@media (max-width: 480px) {
  .ant-pro-table .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-pro-table .ant-table-thead > tr > th,
  .ant-pro-table .ant-table-tbody > tr > th,
  .ant-pro-table .ant-table-thead > tr > td,
  .ant-pro-table .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .ant-pro-table .ant-table-thead > tr > th > span,
  .ant-pro-table .ant-table-tbody > tr > th > span,
  .ant-pro-table .ant-table-thead > tr > td > span,
  .ant-pro-table .ant-table-tbody > tr > td > span {
    display: block;
  }
}
@media (max-width: 575px) {
  .ant-pro-table-toolbar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    margin-bottom: 16px;
    margin-left: 16px;
    padding: 8px;
    padding-top: 16px;
    line-height: normal;
  }
  .ant-pro-table-toolbar-title {
    margin-bottom: 16px;
  }
  .ant-pro-table-toolbar-option {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ant-pro-table-toolbar-default-option {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
}

.ant-pro-table-column-setting {
  width: auto;
}
.ant-pro-table-column-setting-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
}
.ant-pro-table-column-setting-overlay .ant-popover-inner-content {
  width: 200px;
  padding: 0px;
  padding-bottom: 8px;
}
.ant-pro-table-column-setting-overlay .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-pro-table-column-setting-overlay .ant-tree-draggable-icon {
  cursor: grab;
}
.ant-pro-table-column-setting-overlay .ant-tree-treenode {
  align-items: center;
}
.ant-pro-table-column-setting-overlay .ant-tree-treenode:hover {
  background-color: #e6f7ff;
}
.ant-pro-table-column-setting-overlay .ant-tree-treenode:hover .ant-pro-table-column-setting-list-item-option {
  display: block;
}
.ant-pro-table-column-setting-overlay .ant-tree-treenode .ant-tree-checkbox {
  top: 0;
  margin: 0;
  margin-right: 4px;
}
.ant-pro-table-column-setting-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 8px;
}
.ant-pro-table-column-setting-list.ant-pro-table-column-setting-list-group {
  padding-top: 0;
}
.ant-pro-table-column-setting-list-title {
  margin-top: 6px;
  margin-bottom: 6px;
  padding-left: 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.ant-pro-table-column-setting-list-item {
  display: flex;
  align-items: center;
}
.ant-pro-table-column-setting-list-item-title {
  flex: 1;
}
.ant-pro-table-column-setting-list-item-option {
  display: none;
  float: right;
  cursor: pointer;
}
.ant-pro-table-column-setting-list-item-option > span > span.anticon {
  color: #1890ff;
}
.ant-pro-table-column-setting-list-item-option > span + span {
  margin-left: 8px;
}

.ant-pro-table-alert {
  margin-bottom: 16px;
}
.ant-pro-table-alert .ant-alert.ant-alert-no-icon {
  padding: 12px 24px;
}
.ant-pro-table-alert-info {
  display: flex;
  align-items: center;
  transition: all 0.3s;
}
.ant-pro-table-alert-info-content {
  flex: 1;
}
.ant-pro-table-alert-info-option {
  min-width: 48px;
  padding-left: 16px;
}

.ant-pro-table-drag-visible-cell {
  display: flex;
  align-items: center;
}
.ant-pro-table-drag-icon {
  margin-right: 8px;
  color: #999;
  cursor: grab;
}

